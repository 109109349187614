@import '../../sass/mixins';

.Filter {
  @include card;
  display: flex;
  align-items: center;
  height: 63px;
  padding: 0 16px;
  color: var(--dark);

  &__title {
    flex: 1;
    margin-left: 16px;
  }

  .MuiInput-root {
    &::before {
      border-bottom: none !important;
    }
  }
}
