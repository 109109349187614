.Video {
  position: relative;
  width: 110px;
  height: 193px;
  border-radius: 11px;
  overflow: hidden;

  &--large {
    width: 300px;
    height: 490px;
  }

  &__container {
    width: 100%;
    height: 100%;
  }

  video {
    object-fit: cover;
  }
}
