.Messages {
  display: flex;

  &__wrapper {
    width: 100%;
    height: calc(100vh - var(--header-height) - 209px);
    max-height: calc(100vh - var(--header-height) - 209px);
  }

  &__messages {
    display: flex;
    flex-direction: column;
  }

  &__noMessages {
    color: var(--dark);
    margin-top: 150px;
  }
}
