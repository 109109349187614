.VideoDetailsTab {
  display: flex;

  &__wrapper {
    padding: 17px 12px 12px;
    width: 100%;
    height: calc(100vh - var(--header-height) - 209px);
    max-height: calc(100vh - var(--header-height) - 209px);
  }

  &__resolved {
    text-align: center;
    color: var(--grey-300);
    font-weight: bold;
    margin: 20px 0;
  }
}
