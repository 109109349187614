.ReplyForm {
  display: flex;
  background-color: white;
  padding: 0 17px;
  height: 64px;
  align-items: center;

  &__form {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  &__message {
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    border: 0;
    outline: none;
  }
}
