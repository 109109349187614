.Header {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
  background: var(--primary-gradient);
  top: 0;
  left: 0;
  z-index: 999;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    max-width: var(--wrapper-maxwidth);
  }

  &__title,
  &__menuButton {
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: #ffffff !important;
  }
}
