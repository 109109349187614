.Comment {
  $base: &;
  display: flex;
  margin-top: 15px;

  &__bubble {
    flex-grow: 1;
    position: relative;
    padding: 12px;
    margin: 0 12px;
    background: #ffffff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    order: 2;
    max-width: 274px;
  }

  &__author {
    font-size: 14px;
    line-height: 14px;
    color: #757575;
    margin-bottom: 7px;
    font-weight: 600;
  }

  .MuiChip-root {
    //Chip Component
    background-color: var(--secondary) !important;
    color: #ffffff;
  }

  &__content {
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.15px;
  }

  &__time {
    font-size: 12px;
    line-height: 12px;
    color: #666666;
    text-align: center;
    padding: 5px 0;
  }

  &--left {
    align-self: flex-start;

    #{$base}__bubble {
      border-radius: 0px 4px 4px 4px;
      &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid #ffffff;
        border-top: 5px solid #ffffff;
        border-bottom: 5px solid transparent;
        left: -9px;
        top: 0px;
        filter: drop-shadow(-1.7px 1px 1px rgba(0, 0, 0, 0.12));
      }
    }

    #{$base}__userImage {
      order: 1;
    }

    #{$base}__time {
      order: 3;
    }
  }

  &--right {
    align-self: flex-end;

    #{$base}__bubble {
      border-radius: 4px 0px 4px 4px;
      &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 5px solid #ffffff;
        border-right: 5px solid transparent;
        border-top: 5px solid #ffffff;
        border-bottom: 5px solid transparent;
        right: -9px;
        top: 0px;
        filter: drop-shadow(1.7px 1px 1px rgba(0, 0, 0, 0.12));
      }
    }

    #{$base}__userImage {
      order: 3;
    }

    #{$base}__author {
      text-align: right !important;
    }

    #{$base}__time {
      order: 1;
    }
  }

  &--hoopla {
    #{$base}__bubble {
      color: #ffffff !important;
      background: linear-gradient(-45deg, #6950fb 9.85%, #b83af3 105.94%);

      &:before {
        border-top-color: #6950fb !important;
        border-left-color: #6950fb !important;
      }
    }

    #{$base}__author {
      color: #ffffff;
    }
  }
}
